import { Injectable } from '@angular/core';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { shareReplay, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public activeVersion: { name: string, url: string, group: string } = (
        JSON.parse(localStorage.getItem('apiVersion')) || { name: 'Current', url: 'https://api.rezbot.com/api/v1.1/', group: 'LIVE' }
    );

    public subDomain = false;
    public versions: { name: string, url: string, group: string }[] = [];
    public initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public legal: { version?: string, title?: string, terms?: string };

    private apis$: Observable<HttpResponse<any>>;

    constructor(
        private http: HttpClient,
        // private store: Store
    ) { }

    public get isTest(): boolean {
        return this.activeVersion.name === 'Dev1';
    }

    public init(): void {
        this.subDomain = window.location.origin === 'http://localhost:4200';

        this.getApis().subscribe(res => {
            const api = res.body.apis[0];

            this.versions = [
                ...api.api_versions,
                {
                    group: 'TEST',
                    url: 'http://api.geronigo.com.activitysearch.test/api/v1.1/',
                    name: 'Daniil Local',
                    conditions: {
                        urls: ['http://localhost:3000']
                    }
                }
            ].filter(item => {
                if (!item.conditions || window.location.hostname === 'localhost') return true;
                if (item.conditions.urls) return item.conditions.urls.includes(window.location.origin);

                return true;
            });

            if (!this.activeVersion) this.activeVersion = this.versions.find(item => item.name === 'Current') || this.versions[0];
            if (api.legal && api.legal.company) this.legal = api.legal.company;

            this.initialized.next(true);
        });
    }

    public getApis(): Observable<HttpResponse<any>> {
        if (this.apis$) return this.apis$;

        return this.apis$ = this.http.get<any>('https://api.rezbot.com/api/v1.1/apis/?api_id=1', {
            headers: { 'X-API-KEY': 'rezbotui2', 'X-APP-KEY': 'geronigocms' },
            observe: 'response'
        }).pipe(
            shareReplay(1)
        );
    }

    public setVersion(name: string): void {
        if (!name) return;

        localStorage.removeItem('publicKey');

        const parsedName = decodeURIComponent(name.toLowerCase());
        const version = this.versions.find(item => item.name.toLowerCase() === parsedName);

        if (version) this.activeVersion = version;
    }
}
