import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription, Observable, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { TranslateService } from '@ngx-translate/core';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';

import { ApiService } from './core/services/api.service';
import { BreadcrumbsService } from './core/services/breadcrumbs.service';
import { BreadCrumb } from './core/models/breadcrumb.model';
import { SetAdmins, SiteState, ToggleLoading } from './store/site.state';
import { HttpClient } from '@angular/common/http';

moment.updateLocale('en', { week: { dow: DAYS_OF_WEEK.MONDAY, doy: 1 } });

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
    @Select(SiteState.loading) loading: Observable<boolean>;

    private routerEvents: Subscription;
    private connectionHandler = this.notify.bind(this);
    private versionSub: Subscription;
    private socketAdminsSub: Subscription;

    constructor(
        private apiService: ApiService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private breadcrumbs: BreadcrumbsService,
        private router: Router,
        private route: ActivatedRoute,
        private io: Socket,
        private translate: TranslateService,
        private toastr: ToastrService,
        private store: Store,
        private http: HttpClient
    ) {
        this.translate.setDefaultLang('en');
        this.translate.use('en');

        this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons.svg'));
    }

    ngOnInit() {
        this.apiService.init();

        window.addEventListener('offline', this.connectionHandler);
        
        this.socketAdminsSub = this.io.fromEvent('update-admins').pipe(
            switchMap(admins => this.store.dispatch(new SetAdmins(admins)))
        ).subscribe();

        this.io.connect();

        this.getCrumbs();

        this.versionSub = timer(0, 600000).pipe(
            switchMap(() => {
                return this.http.get('https://apicheck.rezbot.com/api_control.php', {
                    params: { no_intercept_all: '1', versions: '1' },
                    headers: { 'X-APP-KEY': 'geronigocms' }
                });
            })
        ).subscribe(({ version }: any) => {
            const currentVersion = +localStorage.getItem('versions');

            localStorage.setItem('versions', version);

            if (!currentVersion || currentVersion >= version) return;

            this.toastr.info('RezBot has been updated, please refresh', '', { disableTimeOut: true });
        });
    }

    ngOnDestroy() {
        this.routerEvents?.unsubscribe();
        this.versionSub?.unsubscribe();
        this.socketAdminsSub?.unsubscribe();

        window.removeEventListener('offline', this.connectionHandler);
    }

    @HostListener('window:beforeunload')
    public beforeunloadHandler() {
        this.store.dispatch([new ToggleLoading(false), new SetAdmins({})]);

        localStorage.removeItem('api_control');
    }

    private getCrumbs(): void {
        this.routerEvents = this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(e => this.buildBreadCrumbs(this.route.root))
        ).subscribe(crumbs => this.breadcrumbs.crumbs = crumbs);
    }

    private buildBreadCrumbs(
        route: ActivatedRoute,
        url = '',
        previousUrl = '',
        crumbs: BreadCrumb[] = []
    ): BreadCrumb[] {
        const label = route.snapshot.data.crumb;
        const path = route.snapshot.url;
        const nextUrl = [url, path].filter(Boolean).join('/').replace(/\/+$/, '');

        if (label && nextUrl && nextUrl !== previousUrl) crumbs.push({ label, url: `/${nextUrl}` });
        if (route.firstChild) return this.buildBreadCrumbs(route.firstChild, nextUrl, url, crumbs);

        return crumbs;
    }

    private notify(): void {
        this.toastr.error('You are offline, check your internet connection');
    }
}
