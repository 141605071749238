import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

import { Clear, SetDirectories, UserState } from '@app/store/user.state';
import { SetAlerts } from '@app/store/site.state';
import { ApiKeyService } from '../services/api-key.service';

@Injectable({
    providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private store: Store,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private apiKeyService: ApiKeyService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.params.has('no_intercept') || req.params.has('no_intercept_response') || req.url.endsWith('.json')) return next.handle(req);

        const user = this.store.selectSnapshot(UserState.profile);

        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse && event.body && (event.body.alerts || event.body.directories)) {
                    if (!req.url.includes('alerts') && event.body.alerts && req.method === 'GET') {
                        this.store.dispatch(new SetAlerts(event.body.alerts));
                    }

                    if (!req.url.includes('directories') && event.body.directories) {
                        this.store.dispatch(new SetDirectories(event.body.directories));
                    }
                }
            }),
            switchMap(event => {
                if (event instanceof HttpResponse && (typeof event.body === 'string') && (!event.body && req.method === 'GET')) {
                    return throwError(()=>new HttpErrorResponse({
                        error: { errors: [{ message: 'An unexpected error occurred' }] }
                    }));
                }

                return of(event);
            }),
            catchError((err: HttpErrorResponse) => {
                if (req.params.has('no_intercept') || req.params.has('no_intercept_response')) return throwError(()=>err);
                if (err.status === 401 && user) return this.toLogin(err);

                if (err.status === 401 && !user) {
                    this.apiKeyService.reset();

                    return throwError(()=>err);
                }

                if (err.error && err.error.errors) err.error.errors.forEach(item => this.toastr.error(item.message));
                else if (err.error && err.error.text) this.toastr.error(err.error.text);
                else this.toastr.error('Unexpected error occurred');

                return throwError(()=>err);
            })
        );
    }

    private toLogin(err: any): Observable<never> {
        this.dialog.closeAll();

        this.store.dispatch(new Clear()).subscribe(() => this.router.navigate(['/login']));

        return throwError(()=>err);
    }
}
