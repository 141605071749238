import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';

import { UserState } from '@app/store/user.state';

@Injectable({
    providedIn: 'root'
})
export class GuestGuard  {
    constructor(
        private router: Router,
        private store: Store
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return combineLatest([
            this.store.selectOnce(UserState.profile),
            this.store.selectOnce(UserState.ui),
        ]).pipe(
            map(([profile, ui]) =>{
             return !profile || !ui || profile?.access_type == 'venue' && ui?.privileges == 'copywriter'  //added copywriter as dev was getting stuck*/
            }),
            tap(allowed => {
                if (!allowed) this.router.navigate(['/']);
            })
        );
    }

}
