import { Injectable } from '@angular/core';
import { MatDateFormats, DateAdapter } from '@angular/material/core';
import * as moment from 'moment';

export const MY_MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);

    for (let i = 0; i < length; i++) valuesArray[i] = valueFunction(i);

    return valuesArray;
}

@Injectable({
    providedIn: 'root'
})
export class MatDateAdapterService extends DateAdapter<moment.Moment> {
    public getYear(date: moment.Moment): number {
        return this.clone(date).year();
    }

    public getMonth(date: moment.Moment) {
        return this.clone(date).month();
    }

    public getDate(date: moment.Moment) {
        return this.clone(date).date();
    }

    public getDayOfWeek(date: moment.Moment) {
        return this.clone(date).isoWeekday() - 1;
    }

    public getMonthNames(style: 'long' | 'short' | 'narrow') {
        if (style === 'long') return moment.months();

        return moment.monthsShort();
    }

    public getDateNames() {
        return range(31, (i) => this.createDate(2017, 0, i + 1).format('D'));
    }

    public getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
        if (style === 'long') return moment.weekdays(true);
        if (style === 'short') return moment.weekdaysShort(true);

        return moment.weekdaysMin(true);
    }

    public getYearName(date: moment.Moment) {
        return this.clone(date).format('YYYY');
    }

    public getFirstDayOfWeek() {
        return 0;
    }

    public getNumDaysInMonth(date: moment.Moment) {
        return this.clone(date).daysInMonth();
    }

    public clone(date: moment.Moment) {
        return date.clone();
    }

    public createDate(year: number, month: number, date: number) {
        if (month < 0 || month > 11) throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
        if (date < 1) throw Error(`Invalid date "${date}". Date has to be greater than 0.`);

        const result = moment({ year, month, date });

        if (!result.isValid()) throw Error(`Invalid date "${date}" for month with index "${month}".`);

        return result;
    }

    public today() {
        return moment();
    }

    public parse(value: any, parseFormat: any) {
        if (value && typeof value === 'string') return moment(value, parseFormat);

        return moment(value);
    }

    public format(date: moment.Moment, displayFormat: any) {
        date = this.clone(date);

        if (!this.isValid(date)) throw Error('MomentDateAdapter: Cannot format invalid date.');

        return date.format(displayFormat);
    }

    public addCalendarYears(date: moment.Moment, years: number) {
        return this.clone(date).add({ years });
    }

    public addCalendarMonths(date: moment.Moment, months: number) {
        return this.clone(date).add({ months });
    }

    public addCalendarDays(date: moment.Moment, days: number) {
        return this.clone(date).add({ days });
    }

    public toIso8601(date: moment.Moment) {
        return this.clone(date).format();
    }

    public deserialize(value: any) {
        let date: moment.Moment;

        if (value instanceof Date) date = moment(value);
        else if (this.isDateInstance(value)) return this.clone(value);

        if (typeof value === 'string') {
            if (!value) return null;

            date = moment(value, moment.ISO_8601);
        }

        if (date && this.isValid(date)) return moment(date);

        return super.deserialize(value);
    }

    public isDateInstance(obj: any) {
        return moment.isMoment(obj);
    }

    public isValid(date: moment.Moment) {
        return this.clone(date).isValid();
    }

    public invalid() {
        return moment.invalid();
    }
}
