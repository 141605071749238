import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserState } from '@app/store/user.state';

import { HttpService } from './http.service';
import { Profile } from '../models/profile.model';

@Injectable({
    providedIn: 'root'
})
export class ApiKeyService {
    private key: Observable<string>;

    constructor(
        private http: HttpService,
        private store: Store
    ) { }

    public get userToken(): string {
        const profile = this.store.selectSnapshot<Profile>(UserState.profile);

        if (profile) return profile.jwt;

        return null;
    }

    public get publicKey(): Observable<string> {
        if (this.key) return this.key;

        return this.key = this.http.get('auths', {
            params: { access: 'public' },
            headers: { 'X-API-KEY': 'geronigobookit', 'X-APP-KEY': 'geronigocms' },
        }).pipe(
            map(res => {
                localStorage.setItem('publicKey', res.body.auths.jwt);

                return res.body.auths.jwt;
            }),
            shareReplay(1)
        );
    }

    public getKey(): Observable<string> {
        if (this.userToken) return of(this.userToken);

        const parsedKey = this.isJson(localStorage.getItem('publicKey'));
        const key = parsedKey && parsedKey.auths ? parsedKey.auths.jwt : parsedKey;

        if (key) return of(key);

        return this.publicKey;
    }

    public reset(): void {
        this.key = null;

        localStorage.removeItem('publicKey');
    }

    private isJson(str: string): any {
        try {
            return JSON.parse(str);
        } catch (error) {
            return str;
        }
    }
}
