import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';

import { UserState } from '@app/store/user.state';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(
        private router: Router,
        private store: Store
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return combineLatest([
            this.store.select(UserState.profile),  //was selectOnce
            this.store.select(UserState.ui),
        ]).pipe(
            /*tap(it=>{
                console.log(it)

                }
             ),*/
            map(([profile, ui]) => !!profile && !!ui && !(profile?.access_type == 'venue' && ui?.privileges == 'copywriter')),
            tap(allowed => {
                if (!allowed) this.router.navigate(['/login']);
            })
        );
    }

}
