import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { GuestGuard } from './shared/guards/guest.guard';
import { NotFoundComponent } from './shared/modules/not-found/components/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canActivate: [GuestGuard]
    },
    {
        path: 'magic',
        loadChildren: () => import('./modules/magic/magic.module').then(m => m.MagicModule),
        canActivate: [GuestGuard]
    },
    {
        path: 'register',
        loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule),
        canActivate: [GuestGuard]
    },
    {
        path: 'validate',
        loadChildren: () => import('./modules/validate/validate.module').then(m => m.ValidateModule),
        canActivate: [GuestGuard]
    },
    {
        path: 'reset',
        loadChildren: () => import('./modules/reset/reset.module').then(m => m.ResetModule),
        canActivate: [GuestGuard]
    },
    {
        path: 'suppliers',
        //loadChildren: () => import('./modules/avail-check/avail-check.module').then(m => m.AvailCheckModule)
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'confirm-booking',
        //loadChildren: () => import('./modules/avail-check/avail-check.module').then(m => m.AvailCheckModule)
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'confirm-supplier',
        //loadChildren: () => import('./modules/supplier-confirm/supplier-confirm.module').then(m => m.SupplierConfirmModule)
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'availability',
        //loadChildren: () => import('./modules/availability/availability.module').then(m => m.AvailabilityModule)
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'supplier-edit',
        //loadChildren: () => import('./modules/availability/availability.module').then(m => m.AvailabilityModule)
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'logo',
        loadChildren: () => import('./modules/logo/logo.module').then(m => m.LogoModule)
    },
    {
        path: 'lite',
        loadChildren: () => import('./modules/lite/lite.module').then(m => m.LiteModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
