import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService, TranslateParser } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl implements OnDestroy {
    private rangeLabel = '{{startIndex}} - {{endIndex}} of {{length}}';
    private langChangeSub: Subscription;

    constructor(
        private translationService: TranslateService,
        private translateParser: TranslateParser
    ) {
        super();

        this.getTranslations();
    }

    ngOnDestroy() {
        if (this.langChangeSub) this.langChangeSub.unsubscribe();
    }

    public getTranslations(): void {
        if (this.langChangeSub) this.langChangeSub.unsubscribe();

        this.langChangeSub =  this.translationService.onLangChange.subscribe(({ translations }) => {
            this.itemsPerPageLabel = get(translations, 'items_per_age');
            this.nextPageLabel = get(translations, 'next_page_label');
            this.previousPageLabel = get(translations, 'previous_page_label');
            this.rangeLabel = get(translations, 'range_label');

            this.changes.next();
        });
    }

    public getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (!length || !pageSize) return `0 of ${length}`;

        length = Math.max(length, 0);

        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return this.translateParser.interpolate(this.rangeLabel, { startIndex: startIndex + 1, endIndex, length });
    }
}
