import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ToastrModule } from 'ngx-toastr';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
//import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps'
import { QuillModule } from 'ngx-quill';
import { MatDialogModule } from '@angular/material/dialog';
//import { NgxMaskModule } from 'ngx-mask';
import { SocketIoModule } from 'ngx-socket-io';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { throwIfAlreadyLoaded } from './module-import.guard';
import { NotFoundModule } from '@app/shared/modules/not-found/not-found.module';

import { ApiService } from './services/api.service';
import { ApiKeyService } from './services/api-key.service';
import { MatDateAdapterService, MY_MOMENT_DATE_FORMATS } from './services/mat-date-adapter.service';
//import { GoogleLoaderService } from './services/google-loader.service';
import { ScriptsService } from './services/scripts.service';

import { LocaleService } from './services/locale.service';
import { PaginatorIntlService } from './services/intl/paginator-intl.service';
import { DatePickerIntlService } from './services/intl/date-picker-intl.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';

import { ApiKeyInterceptor } from './interceptors/api-key.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';

import { UserState } from '@app/store/user.state';
import { SiteState } from '@app/store/site.state';

import { environment } from '../../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'https://apidev2.rezbot.com/config/lang/', '-1.json');
}

@NgModule({ imports: [ToastrModule.forRoot({ timeOut: 5000, progressBar: true }),
        NgxsModule.forRoot([UserState, SiteState], { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsStoragePluginModule.forRoot(),
        SocketIoModule.forRoot({ url: 'https://socket.rezbot.com/' }),
        //SocketIoModule.forRoot({ url: 'http://localhost:3000/' }),
        //AgmCoreModule.forRoot({ apiKey: 'AIzaSyCL8S0-DXPDDWhj_UkSy6FVbZSD5rKdhjk', libraries: ['places'] }),
        GoogleMapsModule,
        QuillModule.forRoot(),
        MatDialogModule,
        //NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NotFoundModule], providers: [
        ApiService,
        ApiKeyService,
        //        GoogleLoaderService,
        LocaleService,
        ScriptsService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: DateAdapter, useClass: MatDateAdapterService },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MY_MOMENT_DATE_FORMATS },
        { provide: MatPaginatorIntl, useClass: PaginatorIntlService },
        { provide: MatDatepickerIntl, useClass: DatePickerIntlService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
