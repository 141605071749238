import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.sass']
})
export class NotFoundComponent {
    @Input() screen = true;
    @Input() text = 'The page you are looing for cannot be found';
}
