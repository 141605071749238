import { State, Action, StateContext, Selector, Store, createSelector } from '@ngxs/store';
import { cloneDeep, groupBy } from 'lodash';
import { Injectable } from '@angular/core';

import { History as HistoryModel } from '@app/core/models/history.model';
import { Alert } from '@app/core/models/alert.model';

export class UpdateHistory {
    static readonly type = '[site] update history';

    constructor(public item: { type: string, id?: string, name: string, query?: any[] }) { }
}

export class SetAlerts {
    static readonly type = '[site] set alerts';

    constructor(public alerts: Alert[]) { }
}

export class SetAdmins {
    static readonly type = '[site] set admins';

    constructor(public admins: any) { }
}

export class SetTabs {
    static readonly type = '[site] set tabs';

    constructor(public items: any[], public tab: string) { }
}

export class SetReport {
    static readonly type = '[site] set report';

    constructor(public report: string, public data: any) { }
}

export class ToggleLoading {
    static readonly type = '[site] toggle loading';

    constructor(public loading?: boolean) { }
}

export class SetIdle {
    static readonly type = '[site] set idle';

    constructor(public idle: boolean) { }
}

export class ClearSiteData {
    static readonly type = '[site] clear all';
}

export interface SiteStateModel {
    history: HistoryModel;
    reports: any;
    alerts: any[];
    tabs: { venue?: any[] };
    admins: { [key: string]: { name: string, admin_id: string } };
    loading: boolean;
    revenues: any;
    customers: any;
    idle: boolean;
}

@State<SiteStateModel>({
    name: 'site',
    defaults: {
        history: {},
        reports: {},
        alerts: [],
        tabs: {},
        admins: {},
        loading: false,
        revenues: null,
        customers: null,
        idle: false
    }
})
@Injectable()
export class SiteState {
    constructor(
        private store: Store
    ) { }

    @Selector()
    static history(state: SiteStateModel): HistoryModel {
        return state.history;
    }

    @Selector()
    static alerts(state: SiteStateModel): any[] {
        return state.alerts;
    }

    @Selector()
    static idle(state: SiteStateModel) {
        return state.idle;
    }

    @Selector()
    static tabs(state: SiteStateModel): { venue?: any[] } {
        return state.tabs;
    }

    @Selector()
    static loading(state: SiteStateModel): boolean {
        return state.loading;
    }

    @Selector()
    static admins(state: SiteStateModel) {
        return state.admins;
    }

    static report(key: string): any {
        return createSelector([SiteState], (state: SiteStateModel) => {
            return state.reports ? state.reports[key] : null;
        });
    }

    @Action(UpdateHistory)
    updateHistory(ctx: StateContext<SiteStateModel>, action: UpdateHistory) {
        const history = cloneDeep(ctx.getState().history);
        const { item: res } = action;

        if (!history[res.type]) history[res.type] = [];

        if (action.item.query) {
            const exists = history[res.type].find(item => JSON.stringify(item.query) === JSON.stringify(res.query));

            if (exists) return;

            history[res.type].unshift(res);
        } else {
            const exists = history[res.type].find(item => item.id === res.id);

            if (exists) return;

            history[res.type].unshift(res);
        }

        if (history[res.type].length > 10) history[res.type].splice(10, history[res.type].length - 10);

        ctx.patchState({ history });
    }

    @Action(SetIdle)
    setIdle(ctx: StateContext<SiteStateModel>, action: SetIdle) {
        ctx.patchState({ idle: action.idle });
    }

    @Action(SetAdmins)
    setAdmins(ctx: StateContext<SiteStateModel>, action: SetAdmins) {
        ctx.patchState({ admins: action.admins });
    }

    @Action(SetAlerts)
    setAlerts(ctx: StateContext<SiteStateModel>, action: SetAlerts) {
        const alertsMap = {
            packages: 'venues',
            sessions: 'venues',
            texts: 'venues',
            restrictions: 'venues',
            rules: 'venues',
            transactions: 'bookings',
            messages: 'bookings',
            components: 'bookings',
            participants: 'bookings',
            payments: 'bookings',
            sales: 'sales',
            vouchers: 'sales'
        };

        const alerts = Object.values(groupBy(Object.values(action.alerts).map((alert: any) => ({
            ...alert,
            link: `/${alertsMap[alert.resource] || alert.resource}`,
            queryParams: { alert: alert.code },
            alerts: alert.alerts ? alert.alerts.map(item => ({
                ...item,
                link: `/${alertsMap[item.resource] || item.resource}`,
                queryParams: { alert: item.code },
            })) : []
        })), 'group')).map(items => ({
            group: items[0].group,
            order: items[0].order,
            alerts: items
        })).sort((a, b) => {
            if (a.order && b.order) {
                if (+a.order < b.order) return -1;
                else if (+a.order > +b.order) return 1;
            }

            if (a.order && !b.order) return -1;
            if (!a.order && b.order) return 1;

            if (a.group > b.group) return 1;
            else if (a.group < b.group) return -1;

            return 0;
        });

        ctx.patchState({ alerts });
    }

    @Action(SetTabs)
    setTabs(ctx: StateContext<SiteStateModel>, action: SetTabs) {
        ctx.patchState({ tabs: { ...ctx.getState().tabs, [action.tab]: action.items } });
    }

    @Action(SetReport)
    setReport(ctx: StateContext<SiteStateModel>, action: SetReport) {
        const { reports = {} } = ctx.getState();
        const report = reports ? reports[action.report] || [] : {}

        ctx.patchState({
            reports: {
                ...reports, [action.report]: { ...report, ...action.data }
            }
        });
    }

    @Action(ToggleLoading)
    toggleLoading(ctx: StateContext<SiteStateModel>, action: ToggleLoading) {
        ctx.patchState({ loading: action.loading === true || action.loading === false ? action.loading : !ctx.getState().loading });
    }

    @Action(ClearSiteData)
    clearSiteData(ctx: StateContext<SiteStateModel>) {
        ctx.setState({
            ...ctx.getState(),
            history: {},
            alerts: [],
            tabs: {},
            reports: {},
            revenues: null,
            loading: false
        });
    }
}
