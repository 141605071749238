import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as moment from 'moment';

import { UserState } from '@app/store/user.state';
import { ApiKeyService } from '../services/api-key.service';
import { ApiService } from '../services/api.service';
import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class ApiKeyInterceptor implements HttpInterceptor {
    constructor(
        private apiKey: ApiKeyService,
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
        private httpService: HttpService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.params.has('no_intercept_all') || req.url.endsWith('.json')) return next.handle(req);

        const version = JSON.parse(localStorage.getItem('apiVersion'));
        const duration = moment.duration(moment().diff(moment(+localStorage.getItem('api_control')))).asMinutes();

        return (
            (version && duration > 60) ? this.http.get('https://apicheck.rezbot.com/api_control.php', {
                params: { no_intercept_all: '1', request_url: version.url },
                headers: { 'X-APP-KEY': 'geronigocms' },
                observe: 'response'
            }) : of(null)
        ).pipe(
            switchMap((res:HttpResponse<any>) => {
                if (res) localStorage.setItem('api_control', Date.now().toString());
                
                if (res && res.body && res.body !== null && res.body?.redirect_url) {
                    this.apiService.activeVersion.url = res.body?.redirect_url || '';
                    req.url.replace(version.url, res.body?.redirect_url || '');
                }
                

                if (req.headers.has('X-API-KEY') || req.params.has('no_intercept') || req.url.endsWith('.svg')) return next.handle(req);

                const profile = this.store.selectSnapshot(UserState.profile);
                const country = this.store.selectSnapshot(UserState.country);
                const admin = this.store.selectSnapshot(UserState.admin);
                const dirsModified = this.store.selectSnapshot(UserState.dirsModifiedSince);

                return this.apiKey.getKey().pipe(
                    switchMap(key => {
                        let request = req.clone({ headers: req.headers.set('X-API-KEY', key) });

                        request = request.clone({
                            headers: request.headers.has('If-Modified-Since') ? request.headers : request.headers.set('If-Modified-Since', (dirsModified || 0).toString()),
                            params: request.params.set('stripe_key', this.httpService.stripeKey)
                        });

                        if (request.body && !request.url.includes('transactions')) request.body.stripe_key = this.httpService.stripeKey;

                        if (req.method === 'GET') request = request.clone({ params: request.params.set('lang', 'all') });

                        if (req.method === 'GET' && profile && profile.company_id === '2' && !req.params.has('no_add_params')) {
                            if (!req.params.has('country') && country) {
                                request = request.clone({ params: request.params.set('country', country.country) });
                            }

                            if (!req.params.has('admin_id') && admin && admin !== '-') {
                                request = request.clone({ params: request.params.set('admin_id', admin) });
                            }
                        }

                        return next.handle(request);
                    })
                );
            })
        );
    }
}
