import { Injectable, OnDestroy } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DatePickerIntlService extends MatDatepickerIntl implements OnDestroy {
    private langChangeSub: Subscription;

    constructor(
        private translationService: TranslateService
    ) {
        super();
    }

    ngOnDestroy() {
        if (this.langChangeSub) this.langChangeSub.unsubscribe();
    }

    public getTranslations(): void {
        if (this.langChangeSub) this.langChangeSub.unsubscribe();

        this.langChangeSub =  this.translationService.onLangChange.subscribe(({ translations }) => {
            this.calendarLabel = get(translations, 'calendar_label');
            this.openCalendarLabel = get(translations, 'open_calendar_label');
            this.prevMonthLabel = get(translations, 'prev_month_label');
            this.nextMonthLabel = get(translations, 'next_month_label');
            this.prevYearLabel = get(translations, 'prev_year_label');
            this.nextYearLabel = get(translations, 'next_year_label');
            this.prevMultiYearLabel = get(translations, 'prev_multi_year_label');
            this.nextMultiYearLabel = get(translations, 'next_multi_year_label');
            this.switchToMonthViewLabel = get(translations, 'switch_to_month_view_label');
            this.switchToMultiYearViewLabel = get(translations, 'switch_to_multi_year_view_label');

            this.changes.next();
        });
    }
}
