import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { Profile } from '@app/core/models/profile.model';
import { Directories, Country } from '@app/core/models/directories.model';
import { UI } from '@app/core/models/ui.model';
import { Subscription } from '@app/core/models/subscription.model';

export class SetCountry {
    static readonly type = '[user] set country';

    constructor(public country: Country) { }
}

export class SetAdmin {
    static readonly type = '[user] set admin';

    constructor(public adminId: string) { }
}

export class SetProfile {
    static readonly type = '[user] set profile';

    constructor(public profile: Profile) { }
}

export class SetDirectories {
    static readonly type = '[user] set directories';

    constructor(public directories: Directories) { }
}

export class SetUi {
    static readonly type = '[user] set ui';

    constructor(public ui: UI) { }
}

export class SetSubscription {
    static readonly type = '[user] set subscription';

    constructor(public subscription: Subscription) { }
}

export class SetDashboard {
    static readonly type = '[user] set ddashboard';

    constructor(public dashboard: any[]) { }
}

export class SetSchedulerVenue {
    static readonly type = '[user] set scheduler venue';

    constructor(public venueId: string) { }
}

export class SetSchedulerStatuses {
    static readonly type = '[user] set scheduler statuses';

    constructor(public statuses: string[]) { }
}

export class SetSchedulerStyle {
    static readonly type = '[user] set scheduler style';

    constructor(public condense: boolean) { }
}

export class Clear {
    static readonly type = '[user] clead';
}

export interface UserStateModel {
    admin: string;
    country: Country;
    profile: Profile;
    directories: Directories;
    ui: UI;
    subscription: Subscription;
    dashboard: any[];
    schedulerVenueId: string;
    schedulerStatuses: string[];
    condenseSchduler: boolean;
    dirsModifiedSince: number;
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        admin: null,
        country: null,
        profile: null,
        directories: null,
        ui: null,
        subscription: null,
        dashboard: null,
        schedulerVenueId: null,
        schedulerStatuses: ['confirmed', 'enquiry','provisional'],
        condenseSchduler: true,
        dirsModifiedSince: null
    }
})
@Injectable()
export class UserState {
    @Selector()
    static country(state: UserStateModel): Country {
        return state.country;
    }

    @Selector()
    static admin(state: UserStateModel): string {
        return state.admin;
    }

    @Selector()
    static profile(state: UserStateModel): Profile {
        return state.profile;
    }

    @Selector()
    static directories(state: UserStateModel): Directories {
        return state.directories;
    }

    @Selector()
    static ui(state: UserStateModel): UI {
        return state.ui;
    }

    @Selector()
    static subscription(state: UserStateModel): Subscription {
        return state.subscription;
    }

    @Selector()
    static dashboard(state: UserStateModel): any[] {
        return state.dashboard;
    }

    @Selector()
    static schedulerVenueId(state: UserStateModel): string {
        return state.schedulerVenueId;
    }

    @Selector()
    static schedulerStatuses(state: UserStateModel): string[] {
        return state.schedulerStatuses;
    }

    @Selector()
    static schedulerStyle(state: UserStateModel): boolean {
        return state.condenseSchduler;
    }

    @Selector()
    static dirsModifiedSince(state: UserStateModel): number {
        return state.dirsModifiedSince;
    }

    static directory(key: string): any {
        return createSelector([UserState], (state: UserStateModel) => state.directories[key]);
    }

    @Action(SetCountry)
    setCountry(ctx: StateContext<UserStateModel>, action: SetCountry) {
        ctx.patchState({ country: action.country });
    }

    @Action(SetAdmin)
    setAdmin(ctx: StateContext<UserStateModel>, action: SetAdmin) {
        ctx.patchState({ admin: action.adminId });
    }

    @Action(SetProfile)
    setProfile(ctx: StateContext<UserStateModel>, action: SetProfile) {
        const { profile } = ctx.getState();

        ctx.patchState({ profile: { ...profile, ...action.profile } });
    }

    @Action(SetDirectories)
    setDirectories(ctx: StateContext<UserStateModel>, action: SetDirectories) {
        const { directories } = ctx.getState();

        ctx.patchState({
            directories: { ...directories, ...action.directories },
            dirsModifiedSince: (action.directories as any)._meta.timestamp
        });
    }

    @Action(SetUi)
    setUi(ctx: StateContext<UserStateModel>, action: SetUi) {
        ctx.patchState({ ui: action.ui });
    }

    @Action(SetSubscription)
    setSubscription(ctx: StateContext<UserStateModel>, action: SetSubscription) {
        ctx.patchState({ subscription: action.subscription });
    }

    @Action(SetDashboard)
    setDashboard(ctx: StateContext<UserStateModel>, action: SetDashboard) {
        ctx.patchState({ dashboard: action.dashboard });
    }

    @Action(SetSchedulerVenue)
    setSchedulerVenue(ctx: StateContext<UserStateModel>, action: SetSchedulerVenue) {
        ctx.patchState({ schedulerVenueId: action.venueId });
    }

    @Action(SetSchedulerStatuses)
    setSchedulerStatuses(ctx: StateContext<UserStateModel>, action: SetSchedulerStatuses) {
        ctx.patchState({ schedulerStatuses: action.statuses });
    }

    @Action(SetSchedulerStyle)
    setSchedulerStyle(ctx: StateContext<UserStateModel>, action: SetSchedulerStyle) {
        ctx.patchState({ condenseSchduler: action.condense });
    }

    @Action(Clear)
    clear(ctx: StateContext<UserStateModel>) {
        ctx.patchState({
            country: null,
            admin: null,
            profile: null,
            directories: null,
            dashboard: null,
            ui: null,
            dirsModifiedSince: null
        });
    }
}
